.svg-container {
  width: 50px; /* Set the desired width for the displayed square */
  height: 50px; /* Set the desired height for the displayed square */
  overflow: hidden; /* Hide any content outside the defined dimensions */
}

.cropped-square {
  width: 20px; /* Set the width of the cropped SVG image */
  height: 18px; /* Set the height of the cropped SVG image */
  background-image: url("../images/sprite-banderas.svg");
  /* background-position: -239px -136px; Position the square within the background */
  /* background-size: 559px 314px; Set the size of the full SVG image */
  background-size: 551px 306px; /* Position the square within the background */
}
