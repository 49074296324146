* {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.main {
  background-color: #f4f4f4;
}

.sec-banner {
  position: relative;
  height: 7rem;
  overflow: hidden;
  margin-bottom: 2rem;
}

.sec-banner img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.sec-hero {
  max-width: 700px;
  margin: 0 auto;
}

.sec-hero__summary {
  color: #263f6a;
  text-align: center;
  font-weight: 100;
  font-size: 20px;
}

.sec-hero__summary > span {
  margin-bottom: 1rem;
  display: inline-block;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 48px;
  color: #263f6a;
  text-align: center;
  margin-bottom: 0;
}

.btn.btn-primary {
  background: #aa272f;
  color: #ffffff;
  height: 42px;
  padding: 0;
  border: none;
  border-radius: 2rem;
  margin-left: 3rem;
  font-family: unset;
}

.btn.btn-primary:hover {
  background: #7e1d24;
  color: #ffffff;
  border: none;
}

.btn-primary > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
}

.sec-hero__summary .btn.btn-primary {
  width: 260px;
  margin: 0 auto;
}

.sec-hero__date {
  text-align: center;
  margin: 1rem auto;
}

.sec-hero__date p {
  color: #263f6a;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.sec-hero__date small {
  font-size: 16px;
  color: #838383;
}

.sec-results {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
}

.card {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: block;
  margin: 0 0 2rem;
}

.card__head {
  background-color: #aa272f;
  color: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-transform: uppercase;
  border-radius: 10px 10px 0 0;
}

.card__body {
  padding: 1rem 2rem;
  border-radius: 0 0 10px 10px;
}

.card__info-time {
  background-color: #caf8ff;
  color: #00b0ca;
  font-weight: 600;
  margin: 0.5rem 0;
}

.card__date {
  color: #263f6a;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 2rem;
}

/* .btn-secondary {
  background-color: #263f6a;
  border-radius: 3rem;
  color: #ffffff;
  border: none;
  text-transform: unset;
  font-size: 14px;
  font-weight: 600;
  gap: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
  width: 100%;
} */

/* .btn-secondary > span {
  display: flex;
  align-items: center;
} */

/* .btn-secondary span span {
  word-break: break-all;
  display: block;
  width: 90%;
  white-space: normal;
  text-align: left;
  letter-spacing: -0.5px;
}

.btn-secondary:hover {
  background-color: #192a47;
  border-radius: 3rem;
  color: #ffffff;
  border: none;
} */

.card__info-through {
  text-decoration: line-through;
  font-weight: bold;
  text-decoration-color: #ac272f;
}

.card__info-top {
  color: #838383;
}

.card__info-top-mw {
  min-width: 86px;
}

@media (max-width: 375px) {
  .card__info-top {
    word-spacing: 100vw;
  }
}

.card__info-from,
.card__info-to {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  flex-direction: column;
  margin-bottom: 2rem;
}

.card__info-to {
  order: 2;
}

.card__info-from svg,
.card__info-to svg {
  flex-shrink: 0;
}

.card__info-from svg {
  order: -1;
}

.card__info-content {
  text-align: center;
  width: 120px;
}
.color-hora-atiempo {
  color: #192a47;
  font-weight: bold;
}

.card__info-time {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 0.72rem;
  border-radius: 0.5rem;
}

.card__info-city {
  color: #263f6a;
  font-weight: 600;
}

.card h3 {
  color: #263f6a;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 2rem;
}

.card--group {
  padding: 2rem;
}

.card--group .card:last-child {
  margin-bottom: 0;
}

.card--a-tiempo .card__head {
  background-color: #00b0ca;
}

.group {
  border-style: dashed;
  border-color: #263f6a;
  border-width: 2px;
  border-radius: 10px;
  padding: 1rem;
  position: relative;
}

.group .card__body {
  border-bottom: 2px solid #aa272f;
  border-left: 2px solid #aa272f;
  border-right: 2px solid #aa272f;
}

.group .card--a-tiempo .card__body {
  border-bottom: 2px solid #00b0ca;
  border-left: 2px solid #00b0ca;
  border-right: 2px solid #00b0ca;
}

.group > span {
  background-color: #ffffff;
  position: absolute;
  top: -1.12rem;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0.5rem;
  width: 200px;
  text-align: center;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  /* .btn-secondary span span {
    word-break: break-all;
    display: block;
    width: 90%;
    white-space: normal;
    text-align: auto;
    letter-spacing: -0.5px;
  } */
  .sec-banner {
    height: 17rem;
  }

  .title {
    text-align: left;
    font-size: 40px;
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  .sec-hero__summary {
    text-align: left;
  }

  .sec-hero__summary .btn.btn-primary {
    margin: 0 0 0 1rem;
  }

  .sec-hero__summary > span {
    margin-bottom: 0;
  }

  .sec-hero__date {
    text-align: center;
    margin: 3rem auto;
  }

  .card__info-from,
  .card__info-to {
    flex-direction: row;
    margin-bottom: 0;
  }

  .card__info-from svg {
    order: 1;
  }

  .card__body {
    display: flex;
    gap: 1.5rem;
  }

  .card__date,
  .card__cta {
    width: 20%;
    display: flex;
    align-items: center;
  }

  .card__date {
    text-align: left;
    margin-bottom: 0;
  }

  .card__info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
  }

  .card__info:after {
    content: "";
    background-color: #bdbdbd;
    width: 100%;
    height: 2px;
    order: 1;
    flex-grow: 1;
  }
}

@media (max-width: 320px) {
  .multileg .rt-carrier-operating p {
    font-size: 0.8rem !important;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .multileg .rt-carrier-operating p {
    font-size: 0.7rem !important;
  }
}

/* iPad Air */

@media (width: 820px) {
  .multileg .rt-carrier-operating p {
    font-size: 0.7rem !important;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .multileg .rt-carrier-operating p {
    font-size: 0.9rem !important;
  }
}

@media (width: 1024px) {
  .multileg .rt-carrier-operating p {
    font-size: 1rem !important;
  }
}

/* XS Devices - Galaxy Fold */

@media (max-width: 280px) {
  .multileg .rt-carrier-operating p {
    font-size: 0.6rem !important;
  }
}

@media (min-width: 360px) and (max-width: 375px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 0rem !important;
  }
}

/* iPhone SE */

@media (width: 375px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 0.8rem !important;
  }
}

/* iPhone XR */

@media (width: 414px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 1rem !important;
  }
}

/* iPhone 12 Pro */

@media (width: 390px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 1rem !important;
  }
}

/* Pixel 5 */

@media (width: 393px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 1rem !important;
  }
}

/*  */

@media (width: 412px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 1rem !important;
  }
}

/* Mobile L */

@media (width: 425px) {
  .multileg .rt-carrier-operating p {
    margin: 0rem 2rem !important;
  }
}

/*  */

@media (width: 912px) {
  .multileg .rt-carrier-operating p {
    font-size: 0.7rem !important;
  }
}
