/* @import "../../../../assets/css/pico/base/breakpoints";
@import "../../../../assets/css/pico/base/media-queries"; */

.react-datepicker__aria-live {
  display: none;
}

/* Calendar navigation Arrows < > */
.react-datepicker__navigation--previous {
  left: 5rem;
}

.react-datepicker__navigation--next {
  right: 5rem;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #ab272f;
  border-width: 1px 1px 0 0;
}

.react-datepicker__navigation-icon {
  top: 5px;
}

.react-datepicker__current-month {
  font-weight: normal;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.react-datepicker__day--selected {
  border-radius: 5px !important;
  background-color: #ab272f !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  text-decoration: none !important;
}

.react-datepicker__day:hover {
  border-radius: 5px !important;
  background-color: #ab272f !important;
  color: white !important;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent !important;
  color: #ccc !important;
  cursor: default !important;
}

.react-datepicker__month-container {
  padding: 6px 10px 12px 10px;
}

@media (max-width: 280px) {
  .react-datepicker__month-container {
    max-width: 270px;
  }

  .rt-widget .information-message-text {
    font-size: 12px;
  }

  /* Calendar navigation Arrows < > */
  .react-datepicker__navigation--previous {
    left: 2rem;
  }

  .react-datepicker__navigation--next {
    right: 2rem;
  }
}

@media (min-width: 281px) and (max-width: 320px) {
  /* Calendar navigation Arrows < > */
  .react-datepicker__navigation--previous {
    left: 3rem;
  }

  .react-datepicker__navigation--next {
    right: 3rem;
  }
}

@media (width: 320px) {
  .react-datepicker__month-container {
    max-width: 300px;
  }
}

@media (min-width: 370px) {
  .react-datepicker__month-container {
    max-width: 370px;
  }
}

.dg-calendar {
  /* @apply .absolute .top-0 .bg-white .overflow-hidden; */
  position: absolute;
  top: 0;
  background-color: white;
  overflow: hidden;
  left: 410px;
  height: 432px;
  width: 865px;
  max-width: 495px;
  z-index: -2;
  margin-left: -508px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: margin-left 0.25s ease-in-out;
}

.dg-dp-months-container {
  /* @apply .flex .w-full .h-full .relative; */
}

.dg-dp-month {
  /* @apply .relative .h-full .w-1/2; */
  border-right: 1px solid #e0e2e5;
  padding: 12px 25px;
}

.dg-dp-disabled {
  /* @apply .pointer-events-none .opacity-25; */
}

.dg-dp-square {
  /* @apply .inline-flex .relative .items-center .justify-center .w-full; */
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  height: 53px;
  margin: 0.066rem;
  width: 3rem;
}

.dg-dp-unit-nav {
  /* @apply .px-2 .inline-flex .items-center .capitalize .text-dp-red .font-body; */
  font-size: 16px;
  height: 30px;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

@media (max-width: 750px) {
  .dg-dp-square {
    height: 50px;
  }
}

.react-datepicker__navigation:focus {
  outline: none !important;
}

.react-datepicker__navigation {
  width: 2rem;
}

.react-datepicker__day--weekend {
  font-weight: 700;
}
